.channel-container {
	display: grid ;
	/* flex-direction: row;
	flex-wrap: wrap; */
	grid-template-columns: 4fr 2fr 1fr;
	grid-template-areas: "channel-data channel-profile channel-control";
	grid-gap: 10px;
	padding: 10px;
  }

  .channel-data {
	  grid-area: "channel-data";
  }

  .channel-control {
	  grid-area: "channel-control";
  }

  .channel-profile {
	grid-area: "channel-profile";
}

.text-label {
	color: black;
	text-align: center;
	font-weight: bold;
}