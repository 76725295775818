.table-container-header {
	display: grid;
	grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
	
	padding:  10px;
	grid-row-gap: 20px;
    justify-items: stretch;
    align-items: stretch;
	justify-content: space-between;
	

}
.table-container {
	/* display: grid;
	grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
	
	padding:  10px; */
	font-size: 18px;

}

.table-column-head {
	/* text-align: center; */
	font-weight: bold;
	border-bottom: 1px;
	border-bottom-style: solid;
	border-color: grey;
	padding-bottom: 20px;
}

.table-column {

}

.header-container {
	display: grid;
	grid-template-columns: 1fr 1fr;
}

.action-item {
	margin-left: auto; 
	margin-right: 0;
}

.table-publisher {
	font-size: 16px;
}


.MuiTableCell-head {
	font-weight: bold !important;
	font-size: 14px !important;
	font-family: 'SF Pro Regular' !important;
}

.MuiTableCell-head {
	font-weight: normal !important;
	font-size: 14px !important;
	font-family: 'SF Pro Regular' !important;
}

.active-status {
	font-size: 10px;
	font-family: 'SF Pro Regular';
	background-color: green;
	text-align: center;
	padding: 3px;
	border-radius: 5px;
	color: white;
}

.waiting-status {
	font-size: 10px;
	font-family: 'SF Pro Regular';
	background-color: rgb(255, 174, 0);
	text-align: center;
	padding: 3px;
	border-radius: 5px;
	color: white;
}

.not-active-status {
	font-size: 10px;
	font-family: 'SF Pro Regular';
	background-color: red;
	text-align: center;
	padding: 3px;
	border-radius: 5px;
	color: white;
}

.MuiFormControl-root {
	margin-top: 5px !important;
}

.cell-title {
	display: block;
	font-size: 14px;
	font-family: 'SF Pro Regular';
	font-weight: bold;
	margin-bottom: 5px;
}

.cell-desc {
	display: block;
	font-size: 10px;
	font-family: 'SF Pro Regular';
	font-weight: bold;
	margin-bottom: 5px;
	color: grey;
}

.cell-normal {
	display: block;
	font-size: 12px;
	font-family: 'SF Pro Regular';
	font-weight: normal;
	margin-bottom: 5px;
	color: black;
}
