
.text-grey {
	color: darkgrey;
}
.text-title {
	color: black;
	font-size: 18px;
	font-weight: bold;
}


.product-container {
	display: grid;
	grid-template-columns: 1fr 4fr 1fr;
	grid-template-areas: "label data control";
	grid-gap: 10px;
}


.product-data {
	grid-area: "data";
}

.product-image {
	grid-area: "label";
}

.product-control {
	grid-area: "control";

}