.container_product {
	font-family: "SF Pro Regular" !important;
	display: grid;
	grid-template-columns: 1fr 3fr;
	grid-template-rows: 2.5fr;
	gap: 10px 10px;
	grid-auto-flow: row;
	grid-template-areas:
	  ". .";
	background-color: white;
	padding-left: 0;

}
.container-galery {
	width: 100%;
	padding: 10px;
}

.container-item {
	width: 100%;
	min-width: none;
	padding: 10px;
}

.sub-container {
	padding: 10px
}

.category {
	font-size: 16px;
	font-weight: bold;
	color: grey;
}

.channel {
	font-size: 18px;
	font-weight: bold;
	color: rgb(48, 33, 33);
}

.product {
	font-size: 21px;
	font-weight: bold;
	color: black;
}

.price {
	font-size: 2rem;
    line-height: 34px;
    font-weight: 800;
    color: var(--N700,rgba(49,53,59,0.96));

}

.discount-price {
	text-decoration-line: line-through;
	color: rgb(78, 77, 77);
}

.profile-label {
	font-family: "SF Pro Regular" !important;
	font-size: 15px;
	color: rgb(82, 79, 79);
	font-weight: bold;
	margin-top: 10px;
}

.profile-value {
	font-family: "SF Pro Regular" !important;
	font-size: 12px;
	color: grey;
	padding-left: 20px;
}

.rounded {
	border-radius: 5px;
}
  @media (max-width: 480px) {
	.container {
		display: block;
		grid-template-columns: 1fr;
		grid-template-rows: 2.5fr;
		gap: 10px 10px;
		grid-auto-flow: row;
		grid-template-areas:
		  ". .";
		background-color: white;
	
	}

	.container-item {
		width: 100%;
	}
  }

  .tab-panel {
	  min-height: 400px;
  }