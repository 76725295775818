#kt_body {
	background-image: url(assets/media/patterns/header-bg.jpg);
    background-repeat: no-repeat;
    background-size: 100%;
	
}

body {
	background-color: rgb(240, 240, 240) !important;
}

.btn {
	margin-bottom: 5px;
}

.box {
	background-color: white;
	border-radius: 5px;
	min-height: 200px;
	padding:  20px;

}
.container-form {
	display: grid;
	
	grid-template-columns: 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 20px;
    justify-items: stretch;
    align-items: stretch;
	justify-content: space-between;
	min-height: 200px; 
	margin-bottom: 20px;

}

.form-title {
	display: block;
	width: 100%;
	font-size: 16px;
	font-weight: bold;
	border-bottom-color: grey;
	border-bottom-style: solid;
	border-bottom-width: 1px;
	margin-bottom: 10px;
	padding-bottom: 10px;
	
}

.form-content {
	display: block;
	padding-top: 10px;
	padding-bottom: 20px;
	border-bottom-color: grey;
	border-bottom-style: none;
	border-bottom-width: 1px;
	min-height: 300px;
}

.form-action {
	display: block;
	float: right;
	margin-right: 0;
	padding-top: 20px;
}

.DayPicker {
	margin-top: 30px;
}

.error-message {
	font-size: 10px;
	color: red;
	font-style: italic;
}

.form-input-label {
	font-weight: 400;
	font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-size: 10px;
	color: rgba(0, 0, 0, 0.6);
}

.img-container {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 20px;
    justify-items: stretch;
    align-items: stretch;
	justify-content: space-between;
	min-height: 200px; 
	margin-bottom: 20px;
}

.form-ctrl {
	display: grid;
	grid-template-columns: 1fr 5fr;
	margin-bottom: 15px;
}

.form-detail-info {
	margin-top: 40px;
	margin-bottom: 20px;;
}

.info-label {
	color: grey;
}

.info-value {
	font-weight: bold;
	color: black;
}

.form-sub-title {
	font-size: 14px;
	font-weight: bold;
	color: black;
	margin-top: 40px;
	margin-bottom: 10px;
	border-bottom: black;
	border-bottom-style: solid;
	border-bottom-width: 1px;
}

.profile-container {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	margin-bottom: 15px;
	grid-row-gap: 10px;
}

.profile-item-label {
	color: black;
	font-weight: bold;
}

.profile-item-value {
	color: grey;
}

.btn-space {
	margin-left: 10px;
}

.product-container {
	display: grid;
	grid-template-columns: 1fr 1fr ;
	margin-bottom: 15px;
	grid-row-gap: 10px;
}

.product-container-item>.title {
	font-size: 16px;
	font-weight: bold;
	color: black
}

.product-container-item>.price {
	font-size: 26px;
	font-weight: bold;
	color: blue
}

.product-container-item>.discount-price {
	font-size: 16px;
	font-weight: bold;
	color: grey;
	text-decoration-line: none;
}

.item-container {
	display: grid;
	grid-template-columns: 1fr 2fr ;
	margin-top: 30px;
	margin-bottom: 30x;
	grid-row-gap: 20px;
}
.item-container> .item-value {
	font-weight: bold;
}

.tab-panel {
	min-height: 100px;
}

.panel-descripsi-container{
	display: grid;
	grid-template-columns: 1fr 1fr ;
	margin-top: 30px;
	margin-bottom: 30x;
	grid-row-gap: 20px;
}

.tab-panel-title {
	font-size: 16px;
	font-weight: bold;
	margin-top: 30px;
	border-bottom-style: solid;
	border-bottom-width: 1px;
	color: grey;
}
.panel-profile-container {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr ;
	margin-top: 30px;
	margin-bottom: 30x;
	grid-row-gap: 20px;
}

.i-value {
	font-weight: bold;
	color: grey;
}

.form-content-2-column {
	display: grid;
	
	grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 20px;
    justify-items: stretch;
    align-items: stretch;
	justify-content: space-between;
	min-height: 200px; 
	margin-bottom: 20px;
}

.setting-container {
	display: grid;
	grid-template-columns: 1fr 2fr;
    grid-column-gap: 10px;
    /* grid-row-gap: 20px; */
    justify-items: stretch;
    align-items: stretch;
	justify-content: space-between;
	min-height: 200px; 
	margin-bottom: 20px;
	margin-top: 20px;
}
.setting-label {
	color: grey;
}

.setting-value {
	color: black;
	font-weight: bold;
}

.l1 {
	color: grey;
}
.l2 {
	color: black;
	font-style: italic;
}
.l3 {
	font-size: 20px;
	font-weight: bold;

}
.p {
	font-size: 25px;
	font-weight: bold;
	color: blue;

}

.campaign-info-title {
	font-weight: bold;
	font-size: 14px;
	margin-top: 20px;
}

.campaign-info-desc {
	font-style: italic;
	color: grey;
	padding-left: 20px;
}