.dashboard-container4 {
	display: grid;
    grid-template-columns: 20% 20% 20% 20%;
    grid-column-gap: 4%;
    grid-row-gap: 20px;
    justify-items: stretch;
    align-items: stretch;
	justify-content: space-between;
	min-height: 200px; 
	margin-bottom: 20px;

}

.dashboard-card {
	padding : 20px;
	/* min-height: 150px; */
	background-color: #FFFFFF;
	border-radius: 5px;

}

.dashboard-card-2 {
	padding : 20px;
	min-height: 150px;

}

.dashboard-card-3 {
	padding : 20px;
	height: 150px;
	border-radius: 5px;

}

.b-black {
	background-color:black;
}

.b-blue {
	background-color: #1263CF;
}


.dashboard-container2 {
	display: grid;
    grid-template-columns: 48% 48%;
    grid-column-gap: 4%;
    grid-row-gap: 20px;
    justify-items: stretch;
    align-items: stretch;
	justify-content: center;
	margin-bottom: 20px;
}

.dashboard-container1 {
	display: grid;
    grid-template-columns: 100%;
    grid-column-gap: 4%;
    grid-row-gap: 20px;
    justify-items: stretch;
    align-items: stretch;
	justify-content: center;
	min-height: 100%;
	margin-bottom: 20px;
}

.dashboard-icon {
	color: grey
}
.dashboard-number {
	font-size: 40px;
	font-weight: bold;
}

.dashboard-status {
	font-size: 12px;
	color: grey
}

.dashboard-headline {
	padding-top: 10px;
	font-size: 22px;
	font-weight: bold;
}

.inventory-container {
	display: grid;
	grid-template-columns: 1fr 2fr;
    grid-column-gap: 10px;
    grid-row-gap: 20px;
    justify-items: stretch;
    align-items: stretch;
	justify-content: center;
}

.inventory-title {
	font-size: 20px;
	font-weight: bold;
}
