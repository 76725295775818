.unload-container {
	display: grid;
	grid-template-columns: 1fr 3fr;
    grid-column-gap: 10px;
    grid-row-gap: 20px;
	margin-top: 20px;
}

.upload-column {
	display: block;
}

.upload-title {
	display: block;
	font-size: 16px;
	font-weight: bold;
	text-align: center;
	margin-bottom: 10px;

}

.upload-desc-title {
	display : inline;
	font-size: 11px;
	font-weight: bold;
	margin-right: 20px;
	margin-bottom: 5px;

}

.upload-desc {
	display: block;
	color: grey;
	font-size: 11px;
	margin-bottom: 5px;

}

.file-container{
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-column-gap: 10px;
}

.file-item {
	display: block;
}

.upload-text-area {
	border:1px solid #e1d9d9;
}

textarea:focus { 
	outline: none !important;
	border-color: #e1d9d9;
}