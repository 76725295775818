.input-label {
	margin-bottom: 10px;
	margin-top: 10px;
}

.product-select {
	display: block;
	margin-right: 10px;
	border: none;
	border-bottom: 1px solid !important;
	border-bottom-width: 1px;
	border-radius: 0;

}

