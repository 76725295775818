.sidebar {
	flex: 1;
	background-color: rgb(173, 207, 173);
	height: calc(100vh - 50px);
	position: sticky;
	top: 50px;

}

.sidebarWrapper {
	padding: 20px;
	color: #555;
}

.sidebarMenu {
	margin-bottom: 10px;
}

.sidebarTitle {
	font-size: 15px;
	color: rgb(230, 218, 218)
}

.sidebarList {
	list-style: none;
	padding: 5px;
}

.sidebarListItem {
	padding: 5px;
	cursor: pointer;
	display: flex;
	align-items: center;
	border-radius: 10px;
}

.sidebarListItem.active, .sidebarListItem:hover{
	background-color: rgb(124, 120, 120);
}

.sidebarIcon {
	margin-right: 10px;
	font-size: 20px !important;
}
