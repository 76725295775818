
@font-face {
	font-family: 'SF Pro Regular';
	font-style: normal;
	font-weight: normal;
	src: local('SF Pro Regular'), url('./SF-Pro-Text-Regular.otf') format('opentype');

}

@font-face {
	font-family: 'SF Pro Bold';
	font-style: normal;
	font-weight: normal;
	src: local('SF Pro Bold'),url('./SF-Pro-Text-Bold.otf') format('opentype');
}

body {
	font-family: SF Pro Regular;
	font-style: normal;
	font-size: 14px;
	background-color: #ffff !important;
}
