.product-page-title-container {
	display: grid;
	grid-template-columns: 1fr 1fr;
	background-color: rgb(187, 232, 243);
	border-radius: 5px;
	padding: 20px;
}

.item-product-page {

}

.main-title {
	font-size: 20px;
	font-weight: bold;
}

.status-container {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;

}

.status-item {

}

.v-center {
	display: flex;
	justify-content: right;
	align-items: center;
}

.form-input-container {
	display: grid !important;
	grid-template-columns: 1fr 4fr;
	align-items: center;
	
}

.form-item-label {
	font-family: "SF Pro Regular" !important;
	color: grey;
	font-weight: normal;
}

